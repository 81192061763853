import { render, staticRenderFns } from "./dialogPost.vue?vue&type=template&id=3e949490&scoped=true"
import script from "./dialogPost.vue?vue&type=script&lang=js"
export * from "./dialogPost.vue?vue&type=script&lang=js"
import style0 from "./dialogPost.vue?vue&type=style&index=0&id=3e949490&prod&lang=scss&scoped=true"
import style1 from "./style.scss?vue&type=style&index=1&id=3e949490&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e949490",
  null
  
)

export default component.exports